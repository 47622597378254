import { render, staticRenderFns } from "./Representation.vue?vue&type=template&id=1f0e5a29&lang=html&"
import script from "./Representation.vue?vue&type=script&lang=js&"
export * from "./Representation.vue?vue&type=script&lang=js&"
import style0 from "./Representation.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports