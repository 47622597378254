<template>
  <div class="container pt-4 mt-4 form data" :class="language">
    <div class="row justify-content-start">
      <div class="col-12">
        <h6 class="title">شرایط عمومی متقاضی جهت اخذ نمایندگی تجهیزات صنعت</h6>
        <ul class="form-data-ul pt-2">
          <li class="form-data-li">دارا بودن حداقل 30 متر فضای نمایشگاهی و با سالن دپوی محصول .</li>
          <li class="form-data-li">قابلیت تخلیه بارهای تا طول 6 متر و وزن محصموله 20 تن .</li>
          <li class="form-data-li">وجود فضای اداری جهت مذاکره و عقد قرارداد با مشتریان.</li>
          <li class="form-data-li">توانایی سرمایه گذاری به میزان انجام تعهدات که از طرف شرکت به ایشان ابلاغ میگردد و توانایی ارائه
            تضامین مورد نیاز.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'FormDataTemplate',
  data(){
    return {
      language:this.$route.params.language,
    };
  }
}
</script>

<style lang="css" scoped>
.title{
  color: #fff;
  font-weight: bolder;
  font-size: 1.25em;
}
.form-data-ul{
  color: #fff;
  max-width: 55%;
  direction: rtl;
  font-weight: bolder;
}
.form-data-li{
  color: #fff;
  list-style-type: circle;
  font-weight: bolder;
}
</style>
