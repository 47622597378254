<template lang="html">
  <div class="representation">
    <representation></representation>
  </div>
</template>

<script>
import RepresentationLayout from '@/components/RepresentationLayout';
export default {
  name:'Representation',
  components: {
    'representation':RepresentationLayout
  }
}
</script>

<style lang="css">
  body{
    overflow-y: scroll;
  }
</style>
