<template>
  <div class="col-10 mo-form">
    <form-data-template></form-data-template>
    <div class="empty">
      <b-alert variant="danger" show v-if="errors">
        <span>خطا !</span>

          <p v-for="(error,field) in errors['personInformation']" :key="field">{{ error[0]}}</p>
          <p v-for="(error,field) in errors['place']" :key="field">{{ error[0]}}</p>
          <p v-for="(error,field) in errors['extraPlace']" :key="field">{{ error[0]}}</p>
          <p v-for="(error,field) in errors['tajhizat']" :key="field">{{ error[0]}}</p>

      </b-alert>
      <b-alert variant="danger" show v-if="data">
        <span>خطا !</span>

          <p>{{ data }}</p>

      </b-alert>
    </div>
    <form-wizard @on-complete="onComplete"
                  @on-loading="setLoading"
                  @on-validate="handleValidation"
                  color="transparent">
            <tab-content title="مشخصات عمومی"
                         icon="ti-user"
                         :before-change="validateAsync"
                         >
                <person-information ref="person" class="form" :border="border"></person-information>
            </tab-content>
            <tab-content title="مشخصات محل مورد نظر"
                         icon="ti-settings"
                         :before-change="validatePlace"
                         >
              <place ref="place" class="form"></place>
            </tab-content>
            <tab-content title="مشخصات تکمیلی محل"
                         icon="ti-file"
                         :before-change="validateMetaPlace"
                         >
              <meta-place ref="extraPlace" class="form"></meta-place>
            </tab-content>
            <tab-content title="امکانات و تجهیزات"
                         icon="ti-check">
              <tajhizat ref="tajhizat" class="form"></tajhizat>
            </tab-content>
            <div class="loader-box" v-if="loadingWizard">
              <div class="loader"></div>
            </div>
            <button class="btn btn-outline" :class="btn" slot="prev" v-if="!loadingWizard">قبلی</button>
            <button class="btn btn-outline" :class="btn" slot="next" v-if="!loadingWizard">مرحله بعد </button>
            <button class="btn btn-outline" :class="btn" slot="finish" v-if="!loadingWizard">ارسال</button>
        </form-wizard>
  </div>
</template>

<script>
import 'vue-form-wizard2/dist/vue-form-wizard.min.css';
import '../assets/themify-icons/themify-icons.css';
import PersonInformation from '@/components/FormWidgetsIncludes/PersonInformation';
import Place from './FormWidgetsIncludes/Place';
import MetaPlace from './FormWidgetsIncludes/MetaPlace';
import Tajhizat from './FormWidgetsIncludes/Tajhizat';
import HttpClient from '@/services/http.service';
import FormDataTemplate from './FormWidgetsIncludes/FormData';
import $ from 'jquery';
export default {
  name:'MoForm',
  props:{
    'border':String,
    'btn':String
  },
  methods: {
     onComplete() {
      this.form['PersonInformation'] = this.$refs.person.form;
      this.form['ExtraPlace'] = this.$refs.extraPlace.form;
      this.form['Place'] = this.$refs.place.form;
      this.form['Tajhizat'] = this.$refs.tajhizat.form;
      this.sendRequest();
    },
    setLoading: function(value) {
       this.loadingWizard = value;
       $('.mo-form').hide();
       setTimeout(() => {
         $('.mo-form').show();
       },1000);
   },
   handleValidation: function(isValid, tabIndex){
      //console.log('Tab: '+tabIndex+ ' valid: '+isValid)
      $('.form').show();
   },
   validateAsync:function() {
     return new Promise((resolve, reject) => {
        setTimeout(async () => {
         let result =  await this.$refs.person.validate();
         if (result) {
           resolve(true);
         }else{
           reject(true);
         }

       }, 1000)
     })
    },
    validatePlace:function() {
      return new Promise((resolve, reject) => {
         setTimeout(async () => {
          let result =  await this.$refs.place.validate();
          if (result) {
            resolve(true);
          }else{
            reject(true);
          }

        }, 1000)
      })
     },
     validateMetaPlace:function() {
       return new Promise((resolve, reject) => {
          setTimeout(async () => {
           let result =  await this.$refs.extraPlace.validate();
           if (result) {
             resolve(true);
           }else{
             reject(true);
           }

         }, 1000)
       })
     },
    async sendRequest(){
      this.setLoading(true);
      try {
        let {status , data} = await HttpClient.post('/fa/representation/request',this.form);
        if (status == 200) {
          this.data = data;
          this.errors = null;
          console.log(this.data);
        }
      } catch (e) {
        this.errors = e.response.data.errors;
      }
      this.setLoading(false);
    }
  },
  data(){
    return {
      form:new Object(),
      errors: null,
      data:null,
      loadingWizard: false,

    };
  },
  components: {
    //TextAreaInput,
    'person-information':PersonInformation,
    Place,
    MetaPlace,
    Tajhizat,
    FormDataTemplate,
  }
}
</script>

<style lang="css">
.mo-form{
  direction: ltr;
}
.fa{
  direction: rtl;
}
.stepTitle{
  color: #fff;
}
.vue-form-wizard .wizard-icon-circle{
  background-color: transparent;
}
.wizard-icon {
  color: #fff;
}
.wizard-icon-circle.md.checked{
  background-image: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);

}
.comment-form{
  border-top: 2px solid ;
  border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  border-image-slice: 1;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.wizard-icon-container{
  background:transparent;
  border-color: transparent;
}
.loader {
  font-size: 10px;
  position: absolute;
  top: 40%;
  left: 40%;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader-box{
  position: absolute;
  z-index: 500;
  background: #fff;
  opacity: 0.3;
  width: 100%;
  height: 100%;
}
.bt{
  border-bottom: none;
  border-left: none;
  border-right: none;
}

</style>
