<template>
  <div class="s">
    <nav-bar></nav-bar>
    <div class="empty"></div>
    <box :border="border">
      <h3 slot="title">
        درخواست مشارکت
      </h3>
      <div class="container" slot="body">
        <div class="row justify-content-center">
          <mo-form :border="border" :btn="btn"></mo-form>
        </div>
      </div>
    </box>
  </div>
</template>

<script>
import Box from './Box';
import Navbar from './Navbar';
import MoForm  from './MoForm';
export default {
  name:'RepresentationLayout',
  data(){
    return {
      border:'',
      btn:'',
      category :this.$route.params.category,
    };
  },
  components: {
    Box,
    'nav-bar':Navbar,
    'mo-form' : MoForm
  },
  created(){
    if (this.category == 'plast') {
      this.border = 'border-plast';
      this.btn = 'btn-plast';
    }else if(this.category == 'tajhiz'){
      this.border = 'border-tajhiz';
      this.btn = 'btn-tajhiz'
    }else{
      this.border = null;
    }
  },
}
</script>

<style lang="css" scoped>
</style>
