<template>
  <div class="container" :class="language">
    <div class="row justify-content-center">
      <div class="col-12 comment-form">
        <select-input label="خودرو تحویل بار" :options="hasCar" id="hasCar" @input="setSelected($event,'hasCar')"></select-input>
      </div>
      <div class="col-12 comment-form">
        <text-input label="تعداد خودرو تحویل بار" type="text" id="carsCount" v-model="form.carsCount"></text-input>
      </div>
      <div class="col-12 comment-form">
        <select-input label="نوع مالکیت خودرو"  :options="malekiyat" id="malekiyatCar" @input="setSelected($event,'malekiyatCar')"></select-input>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
export default {
  name:'Tajhizat',
  components: {
    TextInput,
    SelectInput
  },
  data(){
    return {
      language:this.$route.params.language,
      hasCar:[
        {label:'خودرو حمل بار دارم' , code:1},
        {label:'خودرو حمل بار ندارم' , code:0},
      ],
      malekiyat:[
        {label:'مالک' , code:1},
        {label:'استیجاری' , code:0}
      ],
      form:{
        hasCar:null,
        carsCount:new String(),
        malekiyatCar:null,
      }
    };
  },
  methods: {
    setSelected(evt,prop) {
      this.form[prop] = evt.code;
    }
  }
}
</script>

<style lang="css" scoped>
</style>
