<template>
  <div class="container">
    <ValidationObserver ref="form">
    <div class="row justify-content-center" :class="language">

      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.name')" rules="required|alpha_spaces" type="text" id="name" v-model="form.name"></text-input>
      </div>
      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.pedar')" type="text" id="dad" rules="required|alpha_spaces" v-model="form.dad"></text-input>
      </div>
      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.codemeli')" type="text" id="codeMeli" rules="required|digits:10" v-model="form.codeMeli"></text-input>
      </div>
      <div class="col-12 bt" :class="border">
        <select-input :options="AgeOptions" :label="$t('representationForm.age')" type="text" id="age" @input="setSelected($event,'age')" :value="form.age"></select-input>
      </div>
      <div class="col-12 bt" :class="border">
        <select-input :options="Marred" :label="$t('representationForm.marred')" type="text" id="marred" @input="setSelected($event,'marred')"></select-input>
      </div>
      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.tahsilat')" type="text" id="college" v-model="form.college"></text-input>
      </div>
      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.teleSabet')" type="text" id="tnumber" rules="required|digits:11" v-model="form.tnumber"></text-input>
      </div>
      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.tel')" type="text" id="mobile" rules="required|digits:11" v-model="form.mobile"></text-input>
      </div>
      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.email')" type="text" id="email" rules="required|email" v-model="form.email"></text-input>
      </div>
      <div class="col-12 bt d-flex" :class="border" :v-if="$i18n.locale == 'fa'">
        <div class="col-3">
          <label class="form-label">{{ $t('representationForm.addr') }}</label>
        </div>
        <div class="col-10">
          <select-input :options="Ostan"  type="text" id="ostan" @input="setSelected($event,'ostan')"></select-input>
        </div>
      </div>
      <div class="col-12 bt" :class="border">
        <select-input :options="Soldier" :label="$t('representationForm.sarbazi')"  type="text" id="soldier"  @input="setSelected($event,'soldier')"></select-input>
      </div>
      <div class="col-12 bt" :class="border">
        <text-input :label="$t('representationForm.referer')" type="text" id="name" v-model="form.apco"></text-input>
      </div>
    </div>
    </ValidationObserver>
  </div>
</template>

<script>
import TextInput from '@/components/inputs/TextInput';
import TextAreaInput from '@/components/inputs/TextAreaInput';
import SelectInput from '@/components/inputs/SelectInput';
 import * as VeeValidate from 'vee-validate';
export default {
  name:'PersonInformation',
  props:{
    'border':String
  },
  data(){
    return {
      language:this.$route.params.language,
      AgeOptions:[
        {label:'1340',code:1340},
      ],
      Marred:[
        {label:'مجرد',code:'0'},
        {label:'متاهل',code:'1'},
      ],
      Soldier:[
        {label:'دارای کارت پایان خدمت و یا معافیت غیر پزشکی' , code:'1'},
        {label:'معافیت پزشکی',code:'2'},
        {label:'درحال انجام خدمت' , code:'3'},
        {label:'مشمول خدمت سربازی',code:'4'},
        {label:'هیچکدام',code:'5'},
      ],
      Ostan:[
        {code:"1", label:"تهران"},
        {code:"2", label:"گیلان"},
        {code:"3", label:"آذربایجان شرقی"},
        {code:"4", label:"خوزستان"},
        {code:"5", label:"فارس"},
        {code:"6", label:"اصفهان"},
        {code:"7", label:"خراسان رضوی"},
        {code:"8", label:"قزوین"},
        {code:"9", label:"سمنان"},
        {code:"10", label:"قم"},
        {code:"11", label:"مرکزی"},
        {code:"12", label:"زنجان"},
        {code:"13",label:"مازندران"},
        {code:"14",label:"گلستان"},
        {code:"15", label:"اردبیل"},
        {code:"16", label:"آذربایجان غربی"},
        {code:"17", label:"همدان"},
        {code:"18", label:"کردستان"},
        {code:"19", label:"کرمانشاه"},
        {code:"20", label:"لرستان"},
        {code:"21", label:"بوشهر"},
        {code:"22", label:"کرمان"},
        {code:"23", label:"هرمزگان"},
        {code:"24", label:"چهارمحال و بختیاری"},
        {code:"25", label:"یزد"},
        {code:"26", label:"سیستان و بلوچستان"},
        {code:"27", label:"ایلام"},
        {code:"28", label:"کهگلویه و بویراحمد"},
        {code:"29", label:"خراسان شمالی"},
        {code:"30", label:"خراسان جنوبی"},
        {code:"31", label:"البرز"}
      ],
      form:{
        name:new String(),
        dad: new String(),
        codeMeli:new String(),
        age : null,
        marred:null,
        college:new String(),
        tnumber:new String(),
        mobile:new String(),
        email:new String(),
        ostan:null,
        shahr:null,
        soldier:null,
        apco:new String(),
      },
    };
  },
  components: {
    TextInput,
    SelectInput,
    'ValidationObserver':VeeValidate.ValidationObserver,
  },
  methods: {
      setSelected(evt,prop) {
        this.form[prop] = evt.code;
        //console.log(evt);
        //console.log(this.form.age);
      },
      async validate(){
        const isValid = await this.$refs.form.validate();
        //console.log(isValid);
        return isValid;
      },
      generateYear(){
        let end = 1400;
        let arr = new Array;
        for (let index = 1340; index < end; index++) {
          this.AgeOptions.push({'label':index,'code':index});
        }
      }
    },
    mounted(){
      this.generateYear();
    },
}
</script>

<style lang="css" scoped>
</style>
